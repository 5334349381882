import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import ContactUsCard from "../../cards/ContactUsCard"


const Insulation = () => {

  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Harvistav - Zateplenie rodinných a bytových domov"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Našou najväčšou špecializáciou je zateplenie rodinných a bytových domov.
    Sme tu, aby sme vám pomohli znížiť náklady na energiu a vytvoriť príjemné a úsporné prostredie pre vás a vašu rodinu.
    Spolupracujeme s vami, aby sme zvolili optimálne riešenie, ktoré bude zodpovedať štruktúre vášho domu a vašim individuálnym potrebám.`;
    window.scrollTo({
      top: 0
    })
  }, [])

  const processLeft = [
    "Nižšie náklady na energiu",
    "Zvýšený komfort bývania",
    "Udržiavanie príjemnej teploty v interiéri",
    "Eliminácia chladných miest a kondenzácie",
    "Znižuje vonkajší hluk"
  ]

  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                <span className='green-text-darker'>Zateplenie </span>rodinných a bytových domov
              </h1>
              <img className="fade_logo" src={require("../../../assets/images/footer_fade_logo.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid serviceDetail w-100">
          <div className="container why_us">
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <h4 className="title mb-5">
                    O zateplení
                  </h4>
                  <p>
                    Našou najväčšou špecializáciou je zateplenie rodinných a bytových domov.
                    Sme tu, aby sme vám pomohli znížiť náklady na energiu a vytvoriť príjemné a úsporné prostredie pre vás a vašu rodinu.
                    Spolupracujeme s vami, aby sme zvolili optimálne riešenie, ktoré bude zodpovedať štruktúre vášho domu a vašim individuálnym potrebám.
                  </p>
                  <p>
                  Naším cieľom je zlepšiť tepelnú ochranu vášho domu a znížiť straty tepla cez steny, strechu a podlahy.
                  Kvalitné zateplenie zabezpečuje lepšiu tepelnú izoláciu, čo vedie k úspore energie a nižším nákladom na
                  vykurovanie a chladenie. Používame moderné a efektívne izolačné materiály, ktoré sú odolné voči vlhkosti, plesniam a hmyzu.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={require("../../../assets/images/zateplenie.jpg")} width={"100%"} loading="lazy" alt="Zateplenie rodinných a bytových domov" title="Zateplenie rodinných a bytových domov" />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <h4 className="title mb-5">
                    Výhody zateplenia
                  </h4>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-6 d-flex gap-3 flex-column">
                  {
                    processLeft.map((process, index) =>
                    <div className="d-flex gap-3 align-items-center">
                      <img src={require("../../../assets/images/icons/check-circle.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
                      <h5>
                        {
                          process
                        }
                      </h5>
                    </div>
                    )
                  }

                </div>
                <div className="col-12 col-md-6 d-flex gap-3 flex-column mt-3 mt-md-0">
                  <p>
                    Sme hrdí na našu zodpovednosť voči životnému prostrediu.
                    Naše zatepľovacie riešenia sú navrhnuté tak, aby minimalizovali negatívny vplyv na prírodu a podporovali udržateľnosť.
                    Spolupracujeme s ekologicky priateľskými materiálmi a sledujeme najnovšie normy a smernice pre energetickú účinnosť.
                  </p>
                  <p>
                  Ak sa rozhodnete pre zateplenie vášho rodinného alebo bytového domu,
                  <span className="green-text-darker cursor-pointer" onClick={() => {navigate("/o_nas")}}> kontaktujte nás.</span>
                  </p>

                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../../assets/images/image44.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  )
}

export default Insulation
import { useEffect } from "react"
import ContactUsCard from "../../cards/ContactUsCard"


const Plaster = () => {
  useEffect(() => {
    document.title = "Harvistav - Omietky a stierky"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Precíznymi omietkami a stierkami získate nielen krásne povrchy,
    ale aj dlhotrvajúcu kvalitu a odolnosť voči každodennému opotrebeniu.`;
    window.scrollTo({
      top: 0
    })
  }, [])


  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                Omietky a stierky
              </h1>
              <img className="fade_logo" src={require("../../../assets/images/footer_fade_logo.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid serviceDetail w-100">
          <div className="container why_us">
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <p>
                    Precíznymi omietkami a stierkami získate nielen krásne povrchy,
                    ale aj dlhotrvajúcu kvalitu a odolnosť voči každodennému opotrebeniu.
                  </p>
                  <p>
                    Nezáleží na tom, či preferujete moderný minimalistický štýl,
                    tradičnú eleganciu alebo prírodný vzhľad - naše omietky a stierky dokážu naplniť vaše estetické túžby.
                  </p>
                  <p>
                    Sme hrdí na náš tím, ktorí sa starostlivo venuje každému detailu,
                    aby vaše omietky a stierky boli dielom, na ktoré budete hrdí. Pripravení sme priniesť do vašich priestorov krásu a kvalitu, ktorá pretrváva.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={require("../../../assets/images/omietky_a_stierky.jpg")} width={"100%"} loading="lazy" alt="Omietky a stierky" title="Omietky a stierky" />
                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../../assets/images/image44.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  )
}

export default Plaster
import { useEffect } from "react"
import ContactUsCard from "../cards/ContactUsCard"

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
    document.title = "Harvistav - O nás"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Sme rodinná firma pôsobiaca na trhu od roku 2001.
    Špecializujeme sa na poskytovanie širokého spektra stavebných služieb pre našich klientov.
    Sme oddaní tomu, aby sme prinášali kvalitné stavebné riešenia a aby vyhovovali individuálnym
    potrebám a očakávaniam každého zákazníka.`;
  }, [])

  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                SME <span className="green-text-darker">HARVISTAV<span className="hide-on-phone">,</span> </span> PROFESIONÁLI V OBLASTI STAVEBNÍCTVA
              </h1>
              <img className="fade_logo" src={require("../../assets/images/footer_fade_logo.png")} alt="Harvistav" title="Harvistav" loading="eager" />
            </div>
        </div>
        <div className="container-fluid aboutPage_why_us w-100">
          <div className="container why_us">
            <div className="row">
                <div className="col-12">
                  <h2 className="title">
                    O NÁS
                  </h2>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-9">
                  <p>
                    Sme rodinná firma pôsobiaca na trhu od roku 2001.
                    Špecializujeme sa na poskytovanie širokého spektra stavebných služieb pre našich klientov.
                    Sme oddaní tomu, aby sme prinášali kvalitné stavebné riešenia a aby vyhovovali individuálnym
                    potrebám a očakávaniam každého zákazníka.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <h3 className="title">
                    PREČO SI NÁS VYBRAŤ
                  </h3>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-9">
                  <p>
                    Zameriavame sa na rôzne oblasti, vrátane zateplenia rodinných domov,
                    vyhotovenie fasád, rekonštrukcia rodinných a bytových domov, maliarské a
                    natieračské práce. Naši skúsení pracovníci majú know-how potrebné na
                    úspešné riadenie a dokončenie projektov včas a v rámci rozpočtu.
                    Naším hlavným cieľom je poskytovať stavebné služby najvyššej kvality.
                    Sústreďujeme sa na detaily a starostlivo vyberáme materiály,
                    aby sme zabezpečili trvanlivosť a funkčnosť každého projektu ktorý realizujeme.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12  mt-4">
                      <div className="row">
                        <div className="col-2">
                          <div className="green_bullet">
                            <img src={require("../../assets/images/icons/hands.png")} alt="Proklientský prístup" title="Proklientský prístup" loading="eager" />
                          </div>
                        </div>
                        <div className="col-10">
                          <div>
                            <h4>
                              Proklientský prístup
                            </h4>
                            <p>
                              Ponúkame riešenia, ktoré naplnia očakávania klientov
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  mt-4">
                      <div className="row">
                        <div className="col-2">
                          <div className="green_bullet">
                            <img src={require("../../assets/images/icons/check.png")} alt="Vyškolený personál" title="Vyškolený personál"  loading="eager"/>
                          </div>
                        </div>
                        <div className="col-10">
                          <div>
                            <h4>
                              Vyškolený personál
                            </h4>
                            <p>
                              Ponúkame profesionálne vyškolených odborníkov
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="row">
                    <div className="col-12 mt-4">
                      <div className="row">
                        <div className="col-2">
                          <div className="green_bullet">
                            <img src={require("../../assets/images/icons/quality.png")} alt="Kvalitný materiál" title="Kvalitný materiál" loading="lazy" />
                          </div>
                        </div>
                        <div className="col-10">
                          <div>
                            <h4>
                              Kvalitný materiál
                            </h4>
                            <p>
                              Na realizovanie stavebných používame vysoko kvalitný a certifikovaný materiál
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="row">
                        <div className="col-2">
                          <div className="green_bullet">
                            <img src={require("../../assets/images/icons/communication.png")} alt="Odborné poradenstvo" title="Odborné poradenstvo" loading="lazy" />
                          </div>
                        </div>
                        <div className="col-10">
                          <div>
                            <h4>
                              Odborné poradenstvo
                            </h4>
                            <p>
                              Našim klientom ponúkame odborné poradestvo v oblasti stavebníctva
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../assets/images/image44.png")} alt="Harvistav" title="Harvistav" loading="lazy" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  );
}

export default About
import { useEffect } from "react"
import ContactUsCard from "../../cards/ContactUsCard"


const Cleaning = () => {
  useEffect(() => {
    document.title = "Harvistav - Čistenie a natieranie fasád"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Vykonávame dôkladné čistenie povrchu, odstraňujeme nečistoty, prach, peľ, plesne, hmyz a iné nečistoty,
    ktoré sa môžu nahromadiť na fasádach. Používame vysokotlakové čističe na odstránenie znečistení a tvrdohlavých škvŕn.
    Tieto zariadenia umožňujú intenzívne čistenie fasád a odstraňovanie nečistôt aj z hĺbky povrchu.`;
    window.scrollTo({
      top: 0
    })
  }, [])


  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                <span className='green-text-darker'>Čistenie a natieranie </span>fasád
              </h1>
              <img className="fade_logo" loading="lazy" src={require("../../../assets/images/footer_fade_logo.png")} alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid serviceDetail w-100">
          <div className="container why_us">
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <p>
                    Vykonávame dôkladné čistenie povrchu, odstraňujeme nečistoty, prach, peľ, plesne, hmyz a iné nečistoty,
                    ktoré sa môžu nahromadiť na fasádach. Používame vysokotlakové čističe na odstránenie znečistení a tvrdohlavých škvŕn.
                    Tieto zariadenia umožňujú intenzívne čistenie fasád a odstraňovanie nečistôt aj z hĺbky povrchu.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={require("../../../assets/images/cistenie_a_natieranie_fasad.jpg")} loading="lazy" width={"100%"} alt="Čistenie a natieranie fasád" title="Čistenie a natieranie fasád" />
                </div>
                <div className="col-12 mt-5">
                  <p>
                    V prípade tvrdohlavých nečistôt alebo škvŕn, ktoré nie je možné odstrániť vysokotlakovým umývaním,
                    používame chemické čistidlá a prostriedky. Tieto čistiace látky sú špeciálne vyvinuté na odstránenie
                    rôznych typov nečistôt a škvŕn, pričom zároveň chránia povrch fasády. Po čistení fasády môžeme aplikovať
                    ochranné nátery alebo impregnácie, ktoré zabezpečujú ochranu pred vlhkosťou, UV žiarením a ďalšími vonkajšími faktormi.
                    Tieto ochranné vrstvy zvyšujú trvanlivosť fasády a pomáhajú udržiavať jej estetický vzhľad.
                  </p>
                  <p>
                    Sme tiež pripravení vykonať
                    prípadné opravy, ako sú výmena poškodených tehál, oprava trhlín alebo nedostatkov.
                    Tiež sa môžeme zaoberať údržbou fasády a pravidelným čistením, aby sa udržal jej čistý a pekný vzhľad.
                  </p>
                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../../assets/images/image44.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  )
}

export default Cleaning

import {
  AiFillInstagram,
  AiFillPhone
} from "react-icons/ai"
import {
  IoLogoFacebook
} from "react-icons/io"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  owner: "Štefan Harvila",
  name: "Harvila Štefan-HARVISTAV",
  operations: "Stavebné práce",
  phone_one: "+421 915 863 002",
  phone_two: "+421 905 375 384",
  email: "info@harvistav.sk",
  ico: "37 371 649",
  dic: "1031343907",
  address_city: "Hankovce 131",
  address_psc: "067 12 Koškovce",
  address_country: "Slovensko",
  socials: [
    {
      link: "https://www.google.com",
      icon: IoLogoFacebook,
    },
    {
      link: "https://www.google.com",
      icon: AiFillInstagram,
    },
    {
      link: "tel:+421 915 863 002",
      icon: AiFillPhone,
    },
  ],
  services: [
    {
      title: "Rekonštrukcia rodinných a bytových domov",
      image: require("../assets/images/home_background_crop.jpg"),
      description: `
        Rekonštrukciou rodinných a bytových domov, chceme priniesť
        nový život a hodnotu do existujúcich nehnuteľností.
       `,
      /* bullets:[], */
      path: "/rekonstrukcia_domov_a_bytov"
    },
    {
      title: "Zateplenie rodinných a bytových domov",
      image: require("../assets/images/zateplenie.jpg"),
      description: `Našou najväčšou špecializáciu je zateplenie rodinných a bytových domov.
      Sme tu, aby sme vám pomohli znížiť náklady na energiu a vytvoriť príjemné a úsporné prostredie pre vás a vašu rodinu.
      `,
      path: "/zateplenie_bytov_a_domov"
    },
    {
      title: "Omietky a stierky",
      image: require("../assets/images/omietky_a_stierky.jpg"),
      description: `Omietky a stierky nie sú len bežnými povrchovými úpravami, ale skutočnými umeleckými dielami.
      S láskou k detailom a zmyslom pre estetiku pretvárame každý priestor do vytúženého miesta krásy a elegancie.`,
      path: "/omietky_a_stierky"
    },
    {
      title: "Maliarske a natieračské práce",
      image: require("../assets/images/miarske_a_natieracske_prace.jpg"),
      description: `Nech už ide o maľovanie stien, stropov, dverí, okien alebo iných povrchov, naší skúsení maliari vám pomôžu transformovať váš interiér pomocou kvalitných farieb a štýlových návrhov.
      Zabezpečíme, aby sa dosiahol požadovaný vzhľad a dokonalý finálny výsledok.`,
      path: "/maliarske_a_natieracske_prace"
    },
    {
      title: "Čistenie a natieranie fasád",
      image: require("../assets/images/cistenie_a_natieranie_fasad.jpg"),
      description: `Vykonávame dôkladné čistenie povrchu, odstraňujeme nečistoty, prach, peľ, plesne, hmyz a iné nečistoty, ktoré sa môžu nahromadiť na fasádach.`,
      path: "/cistenie_fasad"
    },
    {
      title: "Pokládka zámkovej dlažby",
      image: require("../assets/images/zamkova_dlazba.jpg"),
      description: `Samotná pokládka zámkovej dlažby je umením precízneho a šikovného zhotovenia povrchu, ktorý zlepšuje vizuálnu atraktivitu prostredia.
      Dokonalá pokládka zámkovej dlažby nám dáva pocit trvácnosti a bezpečia pri každom kroku.`,
      path: "/pokladka_zamkovej_dlazby"
    }
  ],
  reviews: [
    {
      text: `Firma Harvistav (Harvila Štefan) mi už rekonštruovala viacero nehnuteľností,
      od viacerých bytov v Košiciach až po výstavbu RD od základov.
      Vždy som bol nadmieru spokojný s komunikáciou majiteľa firmy, taktiež s prácou stavbárov.`,
      name: "Ľubomír Korijkov"
    },
    {
      text: `Firmu Harvistav sme si vybrali na rekonštrukciu nášho bytu v Košiciach.
      Rekonštrukcia prebehla podľa našich predstáv  precízne,kvalitne a v dohodnutom termíne`,
      name: "Rodina Šuľákova Košice 2023"
    },
    {
      text: `Na základe dobrej spolupráce z predošlých rokov, som oslovil firmu
      Harvistav s.r.o na realizáciu kompletnej rekonštrukcie bytu na
      ulici Hrnčiarskej v Humennom. Byt bol prerobený podľa mojích predstáv na kľúč.
      Firma sa postarala o vývoz odpadu, búracie práce a kompletné zrekonštruovanie bytu.
      Chcel by som oceniť komunikáciu a prístup majiteľa firmy k dodaniu na čas.`,
      name: "Tadeáš Kapko"
    },
    {
      text: `Firma Harvistav nám realizovala celkovú rekonštrukciu 4-izbového bytu.
      S kvalitou odvedenej práce sme boli nadmieru spokojní.
      Oceňujeme komunikáciu, timemanagement, ústretovosť a aktívny prístup pána
      Harvilu a jeho zamestnancov, najmä cenné praktické rady počas prebiehajúcej rekonštrukcie.`,
      name: "Róbert Jano"
    }

  ]
}
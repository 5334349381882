import { useEffect } from "react"
import { useCookies } from "react-cookie";

const Cookies = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['accepted']);

  const handleCookiesAccept = () => {
    setCookie('accepted', "true")
  }
  const handleCookiesRemove = () => {
    setCookie('accepted', "false")
  }
  useEffect(() => {
    document.title = "Harvistav - Cookies"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! = `Táto podstránka poskytuje informácie o používaní analytických súborov cookie na našej webovej stránke.
    Analytické súbory cookie sú dôležité nástroje, ktoré nám umožňujú zhromažďovať anonymné informácie o
    používaní našej stránky a pomáhajú nám lepšie porozumieť preferenciám a potrebám našich návštevníkov.
    Tieto informácie nám zase umožňujú vylepšiť našu stránku a poskytnúť lepšiu užívateľskú skúsenosť.`;
    window.scrollTo({
      top: 0
    })
  }, [])

  return (
    <div className="page_animation">
      <div className="container-fluid servicesPage_intro mb-0 pb-0 w-100">
          <div className="container intro">
            <h1>
              Súbory <span className="green-text-darker"> cookie</span>
            </h1>
            <img className="fade_logo" style={{opacity: 0.8}} src={require("../../assets/images/footer_fade_logo.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
          </div>
      </div>
      <div className="container-fluid servicesPage_types w-100">
        <div className="container why_us">
            <div className="row">
              <div className="col-12">
                <h3>
                  Používanie analytických súborov
                </h3>
                <p>
                  Táto podstránka poskytuje informácie o používaní analytických súborov cookie na našej webovej stránke.
                  Analytické súbory cookie sú dôležité nástroje, ktoré nám umožňujú zhromažďovať anonymné informácie o
                  používaní našej stránky a pomáhajú nám lepšie porozumieť preferenciám a potrebám našich návštevníkov.
                  Tieto informácie nám zase umožňujú vylepšiť našu stránku a poskytnúť lepšiu užívateľskú skúsenosť.
                </p>
                <h3 className="mt-5">
                  Čo sú analytické súbory cookie?
                </h3>
                <p>
                  Analytické súbory cookie sú malé textové súbory, ktoré sú ukladané do vášho zariadenia,
                  keď navštívite našu webovú stránku. Tieto súbory cookie zhromažďujú anonymné informácie o tom,
                  ako používate našu stránku. Zaznamenávajú informácie, ako napríklad počet návštev, čas strávený na stránke,
                  pohyb po stránke a kliknutia na konkrétne prvky. Tieto dáta nám poskytujú štatistiky a analýzy,
                  ktoré nám pomáhajú lepšie porozumieť preferenciám používateľov a zlepšiť obsah a funkčnosť našej stránky.
                </p>
                <h3 className="mt-5">
                  Prečo používame analytické súbory cookie?
                </h3>
                <p>
                  Používame analytické súbory cookie s cieľom zlepšiť našu webovú stránku a poskytnúť vám lepšiu užívateľskú skúsenosť.
                  Tieto súbory cookie nám umožňujú získať cenné informácie o tom, ako používate našu stránku, aké stránky navštevujete
                  najviac, a aké prvky našej stránky vás najviac zaujímajú. Tieto poznatky nám pomáhajú prispôsobiť obsah a
                  zlepšiť usporiadanie stránky tak, aby vyhovovali vašim preferenciám a potrebám.
                </p>
                <h3 className="mt-5">
                  Aké informácie súbory cookie zhromažďujú?
                </h3>
                <p>
                  Analytické súbory cookie zhromažďujú iba anonymné informácie o používaní našej stránky.
                  Tieto informácie zahŕňajú počet návštev, čas strávený na stránke, pohyb po stránke a kliknutia na konkrétne prvky.
                  Súbory cookie nezhromažďujú žiadne osobné údaje
                </p>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <button onClick={handleCookiesRemove} className="button secondary_button w-100 d-flex justify-content-center">
                      Zakázať cookies
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4 mt-sm-0">
                    <button onClick={handleCookiesAccept} className="button w-100 d-flex justify-content-center">
                      Povoliť cookies
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>




    </div>
  )
}

export default Cookies;


interface localProps {
  text: string,
  name: string,
 /*  currentNumber: number,
  prevNumber: number */
}

const ClientReviewElement = (props: localProps) => {
  return (
    <div className="element" key={props.name}>
      <img className="quotes_left" src={require("../../../assets/images/quotes_left.png")} alt="Úvodzovky" title="Úvodzovky" loading="lazy" />
      <p className="text">
        { props.text }
      </p>
      <img className="quotes_right" src={require("../../../assets/images/quotes_right.png")} alt="Úvodzovky" title="Úvodzovky" loading="lazy" />
      <h2 className="sign">
        { props.name }
      </h2>
    </div>
  )
}

export default ClientReviewElement
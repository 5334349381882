import { useNavigate, useLocation  } from "react-router-dom"
import { useEffect, useState } from "react"
import { HiOutlineMenu } from "react-icons/hi"
import { CgClose } from "react-icons/cg"
import config from "../../config/config"
const Header = () => {

  const phone_number = config.phone_one

  const navigate = useNavigate()
  const location = useLocation()

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [menu, setMenu] = useState(false)

  const handleClick = (index: number, path: string) => {
    setSelectedIndex(index)
    navigate(path)
    setTimeout(() => {
      setMenu(false)
    }, 200);
  }

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setSelectedIndex(0)
        break;
      case "/o_nas":
        setSelectedIndex(1)
        break;
      case "/sluzby":
        setSelectedIndex(2)
        break;
      case "/kontakt":
        setSelectedIndex(3)
        break;

      default:
        setSelectedIndex(30)
        break;
    }
  }, [location.pathname])

  return (
    <div className="header container-fluid w-100">
      <div
        className="container w-100"
        style={{
          height: menu ? "370px" : "60px",
          transition: "0.3s"
        }}
      >
        <div className="logo_wrap ">
          <img
            className="logo"
            src={require('../../assets/images/logoSvg.svg').default}
            alt="Harvistav - Stavebné práce" title="Harvistav - Stavebné práce"
            loading="eager"
            onClick={() => { handleClick(0, "/") }}
          />
          <div
            className={`burger_wrap ${menu ? "burger_wrap_open" : "burger_wrap_close"}`}
          >
            <HiOutlineMenu
              className="menu_icon"
              color="#A4CF22"
              size={"50px"}
              onClick={() => {setMenu(true)}}
            />
            <CgClose
              className="menu_icon"
              color="#A4CF22"
              size={"50px"}
              onClick={() => {setMenu(false)}}
            />
          </div>

        </div>
        <div className="list_wrap">
          <ul>
            <li onClick={() => { handleClick(0, "/") }}>
              <span style={{fontWeight: selectedIndex === 0 ? "bold": "initial" }}>DOMOV</span>
              {
                selectedIndex === 0 &&
                <div className="selector"></div>
              }
            </li>
            <li onClick={() => { handleClick(1, "/o_nas") }}>
              <span style={{fontWeight: selectedIndex === 1 ? "bold": "initial" }}>KTO SME</span>
              {
                selectedIndex === 1 &&
                <div className="selector"></div>
              }
            </li>
            <li onClick={() => { handleClick(2, "/sluzby") }}>
              <span style={{fontWeight: selectedIndex === 2 ? "bold": "initial" }}>NAŠE SLUŽBY</span>
              {
                selectedIndex === 2 &&
                <div className="selector"></div>
              }
            </li>
            <li onClick={() => { handleClick(3, "/kontakt") }}>
              <span style={{fontWeight: selectedIndex === 3 ? "bold": "initial" }}>KONTAKT</span>
              {
                selectedIndex === 3 &&
                <div className="selector"></div>
              }
            </li>
          </ul>
        </div>
        <div className="button_wrap">
            <button className="button" onClick={() => { window.open(`tel:${phone_number}`,"_self") }}>
              { phone_number }
              <img src={require("../../assets/images/icons/Arrow.png")}  alt="Telefónne číslo" title="Telefónne číslo" loading="eager" />
            </button>
        </div>
      </div>
    </div>
  )
}

export default Header
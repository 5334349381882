import {
  BsArrowDown
} from "react-icons/bs"

interface localProps {
  index: number,
  active: boolean,
  service: {
    title: string,
    description: string,
    bullets?: Array<String>
    path: string,
    image: any
  },
  onClick: (path: string) => void
}

const ServiceRowCard = (props: localProps) => {
  return (
    <div
      id={`SERVICE_ROW_CARD_${props.index}`}
      className={`service_row_card d-flex flex-column flex-md-row ${props.active ? "" : ""}`}
      onClick={() => {props.onClick(props.service.path)}}
    >
      <div className={`col-12 col-md-6 order-1 order-md-${props.index % 2 === 0 ? "1" : "2"}`}>
        <img src={props.service.image} alt={props.service.title} title={props.service.title} loading="eager" />
      </div>
      <div className={`col-12 col-md-6 p-5 order-2 position-relative order-md-${props.index % 2 === 0 ? "2" : "1"}`}>
        <p style={{fontSize: "40px", lineHeight: "44px", fontWeight: 500}}>{props.service.title}</p>
        <p className="mt-4">
          {props.service.description}
        </p>
        <button className="button">
          <BsArrowDown
            size={"24px"}
            /* style={{
              transform: props.active ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s"
            }} */
            style={{
              transform: "rotate(-90deg)",
              transition: "0.3s"
            }}
          />
        </button>
        <div style={{
          maxHeight: props.active ? "500px" : "0px",
          overflow: "hidden",
          transition: "0.3s"
        }}>
          {
            props.service.bullets && props.service.bullets.map((info:String, index: number) =>
              <p key={index}>
                { info }
              </p>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ServiceRowCard
import config from "../../config/config";
import { useEffect } from "react"

const Contact = () => {


  useEffect(() => {
    document.title = "Harvistav - Kontakt"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Kontaktujte nás na sociálnych sieťach, emailom alebo telefonicky na čísle ${config.phone_one}.`;
    window.scrollTo({
      top: 0
    })
  }, [])
  return (
      <div className="page_animation">
        <img style={{display: "none"}} src={require("../../assets/images/Harvistav_logo.jpg")} alt="Kontakt - Harvistav" loading="eager" title="Stavebné práce - Harvistav" />
        <div className="container-fluid contactPage_intro w-100 pb-4 pb-md-0">
            <div className="container intro">
              <div className="row px-2 px-sm-0 pb-0 pb-md-5 mb-0 mb-md-5">
                <div className="col-12">
                  <h1>
                    Kontaktujte nás
                  </h1>
                </div>
                <div className="col-12 col-md-8">
                  <p style={{color: "#ffffff"}} className="phone">
                    T: <a href={`tel:$${config.phone_one}`}>{ config.phone_one }</a>
                  </p>
                  <p className="email">
                    <a href={`mailto:${ config.email }`}>{ config.email }</a>
                  </p>
                </div>
                <div className="col-12 col-md-4 mt-4 mt-md-0 d-flex flex-column justify-content-between">
                  <div className="mb-2 mb-md-5">
                    <p style={{fontSize: "1.5rem", color: "#ffffff", fontWeight: "500"}}>
                      Adresa:
                    </p>
                  </div>
                  <div>
                    <h2 style={{fontSize: "20px", fontWeight: "500"}} className="name">
                      { config.name }
                    </h2>
                    <h2 style={{fontSize: "20px", fontWeight: "500", color: "#ffffff"}}>
                      { config.operations }
                    </h2>
                    <h2 style={{fontSize: "20px", fontWeight: "500", color: "#ffffff"}}>
                      { config.address_city }
                    </h2>
                    <h2 style={{fontSize: "20px", fontWeight: "500", color: "#ffffff"}}>
                      { config.address_psc }
                    </h2>
                  </div>
                </div>
              </div>
              <img className="fade_logo" style={{opacity: 0.6}} src={require("../../assets/images/footer_fade_logo.png")} loading="eager" alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2615.823003761201!2d21.947316015680578!3d49.032973279305025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473eb241397ce175%3A0x393bd3503e3977cc!2sHarvila%20%C5%A0tefan%20-%20Stavebn%C3%A9%20pr%C3%A1ce!5e0!3m2!1ssk!2ssk!4v1686344334895!5m2!1ssk!2ssk"
          width="110%"
          height="500"
          style={{
            border: "none",
          }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

  );
}



export default Contact;


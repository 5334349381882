import { useEffect } from "react"
import ContactUsCard from "../../cards/ContactUsCard"


const Painting = () => {
  useEffect(() => {
    document.title = "Harvistav - Maliarske a natieračske práce"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Maliarske práce sú pre nás nielen záležitosťou povinnosti, ale aj vášňou, ktorá nás napĺňa a inšpiruje.
    Preto sa snažíme vytvarať prostredie, ktoré zahŕňa pohodu, inováciu a krásu.
    Maliarske práce vykonávame s ohľadom na váš časový plán a snažíme sa minimalizovať nepohodlie počas realizácie projektu.
    Od farebných poradenstiev po konečné dotiahnutie detailov - s nami budete mať skúsených profesionálov pri každom kroku maliarskych prác.`;
    window.scrollTo({
      top: 0
    })
  }, [])


  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                <span className='green-text-darker'>Maliarske </span> a <span className='green-text-darker'>natieračske </span>práce
              </h1>
              <img className="fade_logo" src={require("../../../assets/images/footer_fade_logo.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid serviceDetail w-100">
          <div className="container why_us">
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <h4 className="title mb-5">
                    O maliarskych a natieračských prácach
                  </h4>
                  <p>
                    Maliarske práce sú pre nás nielen záležitosťou povinnosti, ale aj vášňou, ktorá nás napĺňa a inšpiruje.
                    Preto sa snažíme vytvarať prostredie, ktoré zahŕňa pohodu, inováciu a krásu.
                    Maliarske práce vykonávame s ohľadom na váš časový plán a snažíme sa minimalizovať nepohodlie počas realizácie projektu.
                    Od farebných poradenstiev po konečné dotiahnutie detailov - s nami budete mať skúsených profesionálov pri každom kroku maliarskych prác.
                  </p>
                  <p>
                    Postaráme sa o prípravu povrchu, výber správnych vonkajších farieb a aplikáciu, aby sa dosiahol trvanlivý a esteticky príjemný povrch.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={require("../../../assets/images/miarske_a_natieracske_prace.jpg")} width={"100%"} loading="lazy" alt="Natieračské práce" title="Natieračské práce" />
                </div>
              </div>

          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../../assets/images/image44.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  )
}

export default Painting
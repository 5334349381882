import { useNavigate } from "react-router-dom"
import ServiceSubCard from "./ServiceSubCard"

const ServicesCard = () => {
  const navigate = useNavigate()




  return (
    <div className="row services">
      <div className="col-12">
        <p className="title">
          Naše služby
        </p>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-md-4">
            <ServiceSubCard
              index={0}
              path="/rekonstrukcia_domov_a_bytov"
              title="Rekonštrukcia rodinných a bytových domov"
              imgClass="serviceCardImg1"
            />
          </div>
          <div className="col-12 col-md-4">
            <ServiceSubCard
              index={1}
              path="/zateplenie_bytov_a_domov"
              title="Zateplenie rodinných a bytových domov"
              imgClass="serviceCardImg2"
            />
          </div>
          <div className="col-12 col-md-4">
            <ServiceSubCard
              index={2}
              path="/omietky_a_stierky"
              title="Omietky a stierky"
              imgClass="serviceCardImg3"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-4">
            <ServiceSubCard
              index={3}
              path="/maliarske_a_natieracske_prace"
              title="Maliarske a natieračské práce"
              imgClass="serviceCardImg4"
            />
          </div>
          <div className="col-12 col-md-4">
            <ServiceSubCard
              index={4}
              path="/cistenie_fasad"
              title="Čistenie a natieranie fasád"
              imgClass="serviceCardImg5"
            />
          </div>
          <div className="col-12 col-md-4">
            <ServiceSubCard
              index={5}
              path="/pokladka_zamkovej_dlazby"
              title="Pokládka zámkovej dlažby"
              /* subtitle="Napíšte nám" */
              imgClass="serviceCardImg6"
              /* onContactUs={() => navigate("/kontakt")} */
            />
          </div>
        </div>
      </div>
      <div className="row mt-5" >
        <div className="col-12 d-flex justify-content-center">
          <button className="button" style={{zIndex:995}} onClick={() => {navigate("/sluzby")}}>
            Všetky služby
            <img src={require("../../assets/images/icons/Arrow.png")} alt="Všetky služby" title="Všetky služby" loading="lazy" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ServicesCard
import { useEffect } from "react"
import ContactUsCard from "../../cards/ContactUsCard"


const Reconstruction = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
    document.title = "Harvistav - Rekonštrukcia rodinných a bytových domov"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Rekonštrukciou rodinných a bytových domov, chceme
    priniesť nový život a hodnotu do existujúcich nehnuteľností.
    S naším skúseným tímom sme pripravení prevziať vedenie
    vášho rekonštrukčného projektu od začiatku až do konca.
    Bez ohľadu na rozsah práce, od drobných úprav a modernizácií až po
    kompletné prebudovanie, sme vybavení zručnosťami a vedomosťami potrebnými na
    úspešnú realizáciu.`;
  }, [])

  const processLeft = [
    "Vyhotovenie projektovej dokumentácie",
    "Vyhotovenie statických posudkov",
    "Búracie práce",
    "Prípravné stavebné práce",
    "Murárske práce a obkladačské práce"
  ]
  const processRight = [
    "Sadrokartónové systémy",
    "Vodoinštalácia, kúrenie a plyn",
    "Maliarské práce",
    "Elektroinštalácia a osvetlenie"
  ]

  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                <span className='green-text-darker'>Rekonštrukcia </span>rodinných a bytových domov
              </h1>
              <img className="fade_logo" src={require("../../../assets/images/footer_fade_logo.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid serviceDetail w-100">
          <div className="container why_us">
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <h4 className="title mb-5">
                    O rekonštrukcii
                  </h4>
                  <p>
                    Rekonštrukciou rodinných a bytových domov, chceme
                    priniesť nový život a hodnotu do existujúcich nehnuteľností.
                    S naším skúseným tímom sme pripravení prevziať vedenie
                    vášho rekonštrukčného projektu od začiatku až do konca.
                    Bez ohľadu na rozsah práce, od drobných úprav a modernizácií až po
                    kompletné prebudovanie, sme vybavení zručnosťami a vedomosťami potrebnými na
                    úspešnú realizáciu.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={require("../../../assets/images/home_background_crop.jpg")} loading="lazy" width={"100%"} alt="Harvistav" title="Harvistav" />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <h4 className="title mb-5">
                    Kompletná rekonštrukcia zahŕňa
                  </h4>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-6 d-flex gap-3 flex-column">
                  {
                    processLeft.map((process, index) =>
                    <div className="d-flex gap-3 align-items-center">
                      <img src={require("../../../assets/images/icons/check-circle.png")} loading="lazy" alt={process} title={process} />
                      <h5>
                        {
                          process
                        }
                      </h5>
                    </div>
                    )
                  }

                </div>
                <div className="col-12 col-md-6 d-flex gap-3 flex-column mt-3 mt-md-0">
                  {
                    processRight.map((process, index) =>
                    <div className="d-flex gap-3 align-items-center">
                      <img src={require("../../../assets/images/icons/check-circle.png")} alt={process} title={process} />
                      <h5>
                        {
                          process
                        }
                      </h5>
                    </div>
                    )
                  }

                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../../assets/images/image44.png")} alt="Harvistav" title="Harvistav" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  )
}

export default Reconstruction
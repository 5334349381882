import AboutUsCard from "../cards/AboutUsCard";
import ClientReviewCard from "../cards/ClientReviewCard";
import IntroCard from "../cards/IntroCard";
import ServicesCard from "../cards/ServicesCard";
import { useEffect } from "react"
const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Harvistav - Staviame projekty podľa Vašich predstáv"
        //@ts-ignore
        document.getElementsByTagName("META")[4].content! =`Stavebná firma Harvistav Vám ponúka komplexné stavebné služby pre domy, byty alebo kancelárie. S naším skúseným tímom vám garantujeme kvalitné a presné vykonanie práce.`;

    }, [])
    return (
        <div className="page_animation">
            <img
                style={{display: "none"}}
                src={require("../../assets/images/Harvistav_logo.jpg")}
                alt="Stavebné práce - Harvistav"
                loading="eager"
                title="Stavebné práce - Harvistav"
            />
            <div className="container-fluid homePage w-100">
                <div
                    className="background_image_wrap"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/image44.png")})`
                    }}
                >
                </div>
                <div className="container">
                    <IntroCard />
                    <AboutUsCard />

                </div>
            </div>

            <div className="container-fluid bg-black w-100">
                <div className="container">
                    <ServicesCard />
                </div>
            </div>
            <div className="container-fluid w-100">
                <div className="container">
                    <ClientReviewCard />
                </div>
            </div>
        </div>

    );
}

export default Home;


import config from "../../config/config"
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const ContactUsCard = () => {


  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-center">
        <p style={{
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "40px",
          lineHeight: "60px",
          color: "#FFFFFF",
          textAlign: "center"
        }}>
          Máte nápad alebo potrebujete s <br /> niečím poradiť?
        </p>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <h2>
          Kontaktujte nás
        </h2>
      </div>
      {
        <div className="col-12 d-flex justify-content-center gap-3">
          {
                config.socials.map((site, index) =>
                  site.link.includes("tel")
                    ?
                      <site.icon
                        key={index}
                        className="icon"
                        size={"50px"}
                        style={{
                          cursor: "pointer"
                        }}
                        color="#B7ED20"
                        onClick={() => {(site.link.includes("tel")) && window.open(site.link,"_self")/*  : window.open(site.link,) */}}
                      />
                    :
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props: any) => (
                          <Tooltip {...props}>
                            Sociálne siete už čoskoro.
                          </Tooltip>
                        )}
                        placement="bottom"
                      >
                        <span style={{paddingBottom: "0px"}}>
                          <site.icon
                            key={index}
                            className="icon"
                            size={"50px"}
                            style={{
                              cursor: "pointer"
                            }}
                            color="#B7ED20"
                            onClick={() => {(site.link.includes("tel")) && window.open(site.link,"_self")/*  : window.open(site.link,) */}}
                          />
                        </span>
                      </OverlayTrigger>
                )
              }
        </div>
      }
    </div>
  )
}

export default ContactUsCard
import { useNavigate } from "react-router-dom"

const AboutUsCard = () => {
const navigate = useNavigate( )

  return (
    <div className="row">
      <div className="col-12 aboutUs_content">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="row">
              <p className="fade_in_animation_class animation_duration_750" style={{fontSize: "20px", fontWeight: 500}}>KTO SME</p>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="main_text fade_in_animation_class animation_duration_750">
                  {/* Sme <span className="green-text-darker">stavebná spoločnosť </span>
                  s komplexným portfóliom služieb, ponúkajúca najvyššiu kvalitu v odbore stavebníctva. */}
                  Sme <span className="green-text-darker">stavebná firma</span> pôsobiaca na trhu od roku 2001
                  špecializjúca sa na široké spektrum stavebných služieb.
                </p>
                <p className="additional_text">
                  {/* Ponúkame bezproblémovú realizáciu stavby, rekonštrukcie a opravy objektov.
                  Ku všetkým uvedeným službám Vám zaistíme všetko vrátane dodávky a dokumentácie. */}
                  Zameriavame sa na rôzne oblasti, vrátane zateplenia rodinných domov, vyhotovenie fasád,
                  rekonštrukcie rodinných a bytových domov, maliarské a natieračské práce.
                  Prioritou pre nás je úspešné riadenie a dokončenie projektov včas a v rámci rozpočtu v najvyššej kvalite.
                </p>
                <div className="button_wrap mt-4 mt-md-0">
                  <button className="button mt-2 mt-md-4 fade_in_animation_class animation_duration_750" onClick={() => {navigate("/o_nas")}}>
                    Viac o nás
                    <img src={require("../../assets/images/icons/Arrow.png")} alt="Viac o nás" title="Viac o nás" loading="eager" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-1 mt-md-4">
            <div className="experience_box">
              <p className="highlight_text fade_in_animation_class animation_duration_750">
                22+
              </p>
              <h2 style={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "30px"
                }}
                className="fade_in_animation_class"
              >
                Rokov skúseností a praxe
              </h2>
            </div>
            <div className="experience_box mt-5">
              <p className="highlight_text fade_in_animation_class animation_duration_750">
                500+
              </p>
              <h2 style={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "30px"
                }}
                className="fade_in_animation_class"
              >
                Spokojných zákazníkov a klientov
              </h2>
            </div>
            <div className="experience_box mt-5">
              <p className="highlight_text fade_in_animation_class animation_duration_750">
                1000+
              </p>
              <h2 style={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "30px"
                }}
                className="fade_in_animation_class"
              >
                Zrealizovaných projektov
              </h2>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AboutUsCard
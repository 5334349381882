
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/pages/Home"
import Default from "./layouts/Default"
import About from "./components/pages/About";
import Services from "./components/pages/Services";
import Contact from "./components/pages/Contact";
import Cookies from "./components/pages/Cookies";
import Privacy from "./components/pages/Privacy";
import { CookiesProvider } from 'react-cookie';
import Reconstruction from "./components/pages/Services/Reconstruction";
import Insulation from "./components/pages/Services/Insulation";
import Plaster from "./components/pages/Services/Plaster";
import Painting from "./components/pages/Services/Painting";
import Cleaning from "./components/pages/Services/Cleaning";
import Paving from "./components/pages/Services/Paving";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    //@ts-ignore
    document.getElementsByTagName("link")[5].href = window.location.href
  }, [])

  /*
  if (cookies.accepted === "true") {
    initiate google analytics
  }
  */
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Default />}>
              <Route index element={<HomePage />} />
              <Route path="o_nas" element={<About/>} />
              <Route path="sluzby" element={<Services/>} />
              <Route path="kontakt" element={<Contact/>} />
              <Route path="cookies" element={<Cookies/>} />
              {/* wrapper component here with property of a component */}
              <Route path="rekonstrukcia_domov_a_bytov" element={<Reconstruction />} />
              <Route path="zateplenie_bytov_a_domov" element={<Insulation />} />
              <Route path="omietky_a_stierky" element={<Plaster />} />
              <Route path="maliarske_a_natieracske_prace" element={<Painting />} />
              <Route path="cistenie_fasad" element={<Cleaning />} />
              <Route path="pokladka_zamkovej_dlazby" element={<Paving />} />

              <Route path="ochrana_osobnych_udajov" element={<Privacy/>} />
            </Route>
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;

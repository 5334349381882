
import { Outlet } from "react-router-dom";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import { BsArrowUpShort } from "react-icons/bs"
import { useEffect } from "react";
import CookiesBanner from "../components/global/CookiesBanner";
import { useCookies } from "react-cookie";

const Default = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['accepted']);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 100) {
        document.getElementById("scroll_up_button_ID")!.style.transform = "translateX(0px)"
      } else {
        document.getElementById("scroll_up_button_ID")!.style.transform = "translateX(100px)"
      }
    })
  }, [])

  useEffect(() => {
    const loadingScreen = document.getElementById("LOADING_SCREEN")

    const hideAnimationTimeout = setTimeout(() => {
      if (loadingScreen) {
        loadingScreen.classList.add("loading_screen_hide_animation")
        setTimeout(() => {
          loadingScreen.style.display = "none"
          document.body.style.overflow = ""
        }, 500)
      }
    }, 1000)

    const checkScroll = () => {
      if (loadingScreen && !loadingScreen.classList.contains("loading_screen_hide_animation")) {
        document.body.style.overflow = "hidden"
      }
    }

    checkScroll()
    window.addEventListener("scroll", checkScroll)

    return () => {
      clearTimeout(hideAnimationTimeout)
      window.removeEventListener("scroll", checkScroll)
    }
  }, [])

  return (
    <>
      <Header />
        <div>
          { !cookies.accepted && <CookiesBanner /> }
          <Outlet />
        </div>
      <Footer />
      <div className="loading_screen" id="LOADING_SCREEN">
        <img
          src={require("../assets/images/harvistav_logo_loading.png")}
          alt="Harvistav"
        />
      </div>
      <div
        id="scroll_up_button_ID"
        className="scroll_up_button"
      >
        <BsArrowUpShort
          color="#080513"
          size={"40px"}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            })
          }}
        />
      </div>
    </>
  );
};

export default Default;


import { useNavigate } from "react-router-dom"

const IntroCard = () => {
const navigate = useNavigate()

  return (
    <div className="row">
      <div className="col-12 col-md-12 col-lg-6 homePage_content">
        <div className="texts">
          <h1>
            <span>Staviame projekty</span> podľa <br />
            Vašich predstáv
          </h1>
          <h2 className="mt-4 " style={{ fontSize: "20px", color: "#ffffff", fontWeight: 300, lineHeight: "30px"}}>
            Či už sa jedná o domy, byty alebo o kancelárie,
            ako stavebná firma sme Vám plne k dispozícii.
          </h2>
          <button className="button mt-5 " onClick={() => {navigate("/sluzby")}}>
            Zobraziť viac
            <img src={require("../../assets/images/icons/Arrow.png")} alt="Zobraziť viac" title="Zobraziť viac" loading="eager"/>
          </button>
        </div>
      </div>
      <div className="col-12 col-md-6 homePage_img_wrap">
        <img src={require("../../assets/images/rekonstrukcia_domov.jpg")}  alt="Rekonštrukcia domov" title="Rekonštrukcia domov" loading="eager" />
      </div>
    </div>
  )
}

export default IntroCard
import { useNavigate } from "react-router-dom"
import config from "../../config/config"
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const Footer = () => {

  const navigate = useNavigate()
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer container-fluid">
      <div className="container">
      <img className="footer_fade_logo" src={require("../../assets/images/footer_fade_logo.png")} alt="Harvistav" title="Harvistav" loading="lazy" />
        <div className="row">
          <div className="col-12 col-md-3 logo_wrap">
            <img src={require("../../assets/images/logo_dark.png")} alt="Harvistav" title="Harvistav" loading="lazy" />
          </div>
          <div className="col-12 col-sm-8 col-md-3 contact_wrap mt-5 mt-md-0 text-center text-sm-start">
            <p className="title_text">
              Kontakt
            </p>
            <p>
              <b>{config.owner}</b>
            </p>
            <br />
            <p>
              <b>T: </b>
              <span><a href={`tel:$${config.phone_one}`}>{ config.phone_one }</a></span>
              <br />
              <span className="second_number"><a href={`tel:${config.phone_two}`}>{ config.phone_two }</a></span>
            </p>
            <p>
              <b>E: </b><span><a href={`mailto:${ config.email }`}>{ config.email }</a></span>
            </p>
            <p className="mt-4 mt-md-5">
              <b>IČO: </b><span>{config.ico}</span>
            </p>
            <p>
              <b>DIČ: </b><span>{ config.dic }</span>
            </p>
          </div>
          <div className="col-12 col-sm-4 col-md-3 mt-5 mt-md-0 address_wrap text-center text-sm-start">
            <p className="title_text">
              Adresa
            </p>
            <p>
              <span>
                { config.address_city }
              </span>
            </p>
            <p>
              <span>
                { config.address_psc }
              </span>
            </p>
            <p>
              <span>
                { config.address_country }
              </span>
            </p>
            {/* <p className="mt-4 mt-md-5" style={{cursor: "pointer"}} onClick={() => navigate("/ochrana_osobnych_udajov")}>
              <span>
                Ochrana osobných údajov
              </span>
            </p> */}
            <p className="mt-4 mt-md-5" style={{cursor: "pointer"}} onClick={() => navigate("/cookies")}>
              <span>
                Cookies
              </span>
            </p>
          </div>
          <div className="col-12 col-md-3 mt-5 mt-md-0 socials_wrap">

            <p className="title_text">
              Sociálne siete
            </p>
            <div className="icons_wrap">
              {
                config.socials.map((site, index) =>
                  site.link.includes("tel")
                    ?
                      <site.icon
                        key={index}
                        className="icon"
                        size={"50px"}
                        onClick={() => {(site.link.includes("tel")) && window.open(site.link,"_self")/*  : window.open(site.link,) */}}
                      />
                    :
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props: any) => (
                          <Tooltip {...props}>
                            Sociálne siete už čoskoro.
                          </Tooltip>
                        )}
                        placement="bottom"
                      >
                        <span style={{paddingBottom: "15px"}}>
                          <site.icon
                            key={index}
                            className="icon"
                            size={"50px"}
                            onClick={() => {(site.link.includes("tel")) && window.open(site.link,"_self")/*  : window.open(site.link,) */}}
                          />
                        </span>
                      </OverlayTrigger>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <span
        style={{
          fontWeight: 400,
          position: "absolute",
          bottom: "5px",
          left: "5px"
        }}
      >
        © Harvistav s.r.o. {currentYear}
      </span>
    </div>
  )
}

export default Footer
import { useEffect } from "react"
import ContactUsCard from "../../cards/ContactUsCard"


const Paving = () => {
  useEffect(() => {
    document.title = "Harvistav - Pokládka zámkovej dlažby"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`V ponuke je kompletná pokládka zámkovej dlažby od zamerania a návrhu až po
    dokončovacie práce ako sú upratovanie a čistenie povrchu dlažby`;
    window.scrollTo({
      top: 0
    })
  }, [])

  return (
      <div className="page_animation">
        <div className="container-fluid aboutPage_intro w-100">
            <div className="container intro">
              <h1>
                <span className='green-text-darker'>Pokládka </span>zámkovej dlažby
              </h1>
              <img className="fade_logo" src={require("../../../assets/images/footer_fade_logo.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid serviceDetail w-100">
          <div className="container why_us">
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <h4 className="title mb-5">
                    Proces pokládky zámkovej dlažby
                  </h4>
                  <h5>
                    1. Vyznačenie plochy, zameranie, návrh
                  </h5>
                  <h5>
                    2. Príprava podkladu
                  </h5>
                  <p>
                    Pred pokladkou zámkovej dlažby je dôležité pripraviť podklad.
                    To zahŕňa odstránenie starého povrchu, ak je to potrebné, a vytvorenie rovnej a stabilnej plochy.
                    Podklad by mal byť vyrovnaný a dobre zhutnený.
                  </p>
                  <h5>
                    3. Výkop a odvodnenie
                  </h5>
                  <p>
                    Ak je to potrebné, vykopeme povrch a vytvoríme riadne odvodnenie.
                    To je dôležité pre zabezpečenie správneho odtoku vody a zabránenie vzniku záliviek.
                  </p>
                  <h5>
                    4. Založenie obrubníkov
                  </h5>
                  <p>
                    Podobne ako pri samotnej dlažbe, je potrebné pripraviť podklad pre obrubníky.
                    To zahŕňa odstránenie starého povrchu a vytvorenie rovnej a stabilnej plochy.
                    Podklad by mal byť vyrovnaný a dobre zhutnený.
                  </p>
                  <h5>
                    5. Piesková vrstva alebo jemný štrk
                  </h5>
                  <p>
                    Na pripravený podklad sa nanáša vrstva hrubozrnného piesku alebo jemného štrku.
                    Nasledne sa rovnomerne rozprestiera a zhutňuje, aby sa vytvorila pevná a vyrovnaná podložka pre dlažbu.
                  </p>
                  <h5>
                    6. Pokladka dlažby
                  </h5>
                  <p>
                    Po príprave podkladu nasleduje samotná pokladka zámkovej dlažby.
                    Dlažobné kamene sa umiestňujú do požadovanej vzorovej štruktúry.
                    Prikladajú sa tak, aby tvorili rovný povrch s minimálnymi medzerami.
                  </p>
                  <h5>
                    7. Vibrovanie a zhutnenie
                  </h5>
                  <p>
                    Po pokladke dlažby sa používa vibračná doska alebo vibračný valec na zhutnenie dlažobných kameňov.
                    Tým sa zabezpečí ich pevné a stabilné usadenie v podklade.
                  </p>
                  <h5>
                    8. Plnenie spádov a medzier
                  </h5>
                  <p>
                    Po zhutnení dlažby sa vykonáva plnenie spádov a medzier medzi dlažobnými kameňmi.
                    Na tento účel sa používa špeciálny piesok, ktorý sa rozprestiera do medzier a potom sa zhutňuje a vyrovnáva.
                  </p>
                  <h5>
                    9. Dokončovacie práce
                  </h5>
                  <p>
                    Nakoniec sa vykonávajú dokončovacie práce, ktoré zahŕňajú napríklad upratovanie,
                    odstránenie prebytočného materiálu a vyčistenie povrchu dlažby.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={require("../../../assets/images/zamkova_dlazba.jpg")} width={"100%"} loading="lazy" alt="Pokládka zámkovej dlažby" title="Pokládka zámkovej dlažby" />
                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
          <img className="servicesPage_bottom_background_image" src={require("../../../assets/images/image44.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
          <div className="container servicesPage_have_an_idea">
            <ContactUsCard />
          </div>
      </div>
    </div>
  )
}

export default Paving
import { useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie";
import { useEffect } from "react"

const CookiesBanner = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['accepted']);

  const navigator = useNavigate()
  const handleCookiesAccept = () => {
    setCookie('accepted', "true")
  }
  const handleCookiesRemove = () => {
    setCookie('accepted', "false")
  }

  useEffect(() => {
    console.log(cookies.accepted)
  }, [cookies.accepted])


  return (
    <div className="cookies_banner px-3 px-md-5 py-4 mx-2">
      <div className="row">
        <div className="col-12 d-flex gap-3">
          <div>
            <img src={require("../../assets/images/icons/cookies.png")} alt="Cookies" title="Cookies" loading="lazy" />
          </div>
          <h1 style={{fontSize: "32px"}}>
            Cookies
          </h1>
        </div>
        <div className="col-12 d-flex gap-5">
          <div className="row d-flex justify-content-between w-100">
            <div className="col-12 col-md-6">
              <p>Súbory cookie používame na zlepšenie vášho zážitku a poskytovanie prispôsobeného obsahu.</p>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-6 p-0 d-flex justify-content-end">
                  <div>
                    <button onClick={handleCookiesRemove} className="button secondary_button">Zrušiť</button>
                  </div>
                </div>

                <div className="col-12 mt-2 mt-md-0 col-md-8 col-lg-6 p-0 d-flex justify-content-between justify-content-sm-end">
                  <div className="d-flex align-items-center justify-content-start d-sm-none">
                    <button onClick={() => { navigator("/cookies")}} className="cookies-more-button" style={{transform: "translateX(12px)"}}>Zistiť viac</button>
                  </div>
                  <div>
                    <button onClick={handleCookiesAccept} className="button">Povoliť cookies</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-none d-sm-flex">
          <button onClick={() => { navigator("/cookies")}} className="cookies-more-button">Zistiť viac</button>
        </div>
      </div>
    </div>
  )
}

export default CookiesBanner
import { useState/* , useEffect  */} from "react"
import ClientReviewElement from "./elements/ClientReviewElement"
import {
  BsArrowLeftShort
} from "react-icons/bs"
import {
  BsArrowRightShort
} from "react-icons/bs"
import config from "../../config/config"
const ClientReviewCard = () => {

  const reviews = config.reviews

  const [selectedReviewIndex, setSelectedReviewIndex] = useState(0)
  /* const [prevIndex, setPrevIndex] = useState(reviews.length-1) */
  /* const [currentReview, setCurrentReview] = useState(reviews[selectedReviewIndex]) */

  const handlePrevReview = () => {
    /* setPrevIndex(selectedReviewIndex) */
    setSelectedReviewIndex(prev => {
      return prev === 0 ? reviews.length - 1 : prev - 1
    })
  }
  const handleNextReview = () => {
    /* setPrevIndex(selectedReviewIndex) */
    setSelectedReviewIndex(prev => {
      return prev === reviews.length - 1 ? 0 : prev + 1
    })
  }

 /*  useEffect(() => {
    const myInterval = setInterval(() => {
      handleNextReview()
    }, 7000);
    return () => clearInterval(myInterval)
  }, []) */


  return (
    <div className="row clientReviewCard">
      <div className="col-12 d-flex justify-content-center">
        <h2 className="title mt-5">Naši spokojní klienti</h2>
      </div>
      <div className="col-12 col-md-1 left_arrow d-flex justify-content-center align-items-center">
        {
          reviews.length > 1 &&
          <div className="arrow">
            <BsArrowLeftShort
              size={"40px"}
              onClick={handlePrevReview}
            />
          </div>
        }
      </div>
      <div className="col-12 col-md-10 element_wrap">
        <ClientReviewElement
          text={ reviews[selectedReviewIndex].text }
          name={ reviews[selectedReviewIndex].name }
          /* currentNumber={selectedReviewIndex}
          prevNumber={prevIndex} */
        />
      </div>
      <div className="col-12 col-md-1 bottom_arrows_wrap d-flex justify-content-center align-items-center">
        {
          reviews.length > 1 &&
            <div className="arrow left">
              <BsArrowLeftShort
                size={"40px"}
                onClick={handlePrevReview}
              />
            </div>
        }
        {
          reviews.length > 1 &&
            <div className="arrow">
              <BsArrowRightShort
                size={"40px"}
                onClick={handleNextReview}
              />
            </div>
        }
      </div>
    </div>
  )
}

export default ClientReviewCard
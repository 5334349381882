import { BsArrowRightShort } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
interface localProps {
  index: number,
  title: string,
  subtitle?: string,
  path: string,
  imgClass: string,
  onContactUs?: () => void
}
const ServiceSubCard = (props: localProps) => {

  const navigator = useNavigate()

  const handleCardClick = () => {
    /* props.onContactUs ? props.onContactUs() : navigator(`/sluzby?element=${props.index}`) */
    navigator(props.path)
  }

  return (
    <div className={`serviceSubCard ${props.imgClass}`} style={{cursor: "pointer"}} onClick={handleCardClick}>
      <div className="serviceSubCard_background_blur"></div>
      <div className="row h-100">
        <div className="col-12 h-100 d-flex justify-content-center align-items-center text-center">
          <p className="service_title_text">
            {props.title}
          </p>
          {
            props.subtitle &&
            <h4 className="mt-5">
              {props.subtitle}
            </h4>
          }
        </div>
        <div className="col-12 h-75">
          <div className="icon_button local_icon_button">
            <BsArrowRightShort
              size={"50px"}
              onClick={handleCardClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceSubCard
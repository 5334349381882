import { useEffect } from "react"

const Privacy = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
    document.title = "Harvistav - Ochrana osobných údajov"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Naša spoločnosť má niekoľkoročnú pôsobnosť v oblasti stavebníctva a zrealizovala desiatky zákaziek, na konci ktorých boli vždy spokojní zákazníci, z mnohých sa stali naši priatelia. Kvalitný materiál a profesionálne vyškolený
    personál sú zárukou tej najvyššej kvality.`;
  }, [])

  return (
    <div className="page_animation privacy_page">
      <div className="container-fluid privacyPage_intro w-100">
          <div className="container intro">
            <h1>
              Ochrana osobných údajov
            </h1>
            <img className="fade_logo" src={require("../../assets/images/footer_fade_logo.png")} alt="Harvistav" title="Harvistav" loading="lazy" />
          </div>
      </div>
      <div className="container-fluid privacyPage_content pb-5 pt-md-0 w-100">
        <div className="container ">
            <div className="row py-4">
              <div className="col-12">
                <p>
                  Naša spoločnosť má niekoľkoročnú pôsobnosť v oblasti stavebníctva a zrealizovala desiatky zákaziek, na konci ktorých boli vždy spokojní zákazníci, z mnohých sa stali naši priatelia. Kvalitný materiál a profesionálne vyškolený
                  personál sú zárukou tej najvyššej kvality.
                </p>
                <p>
                  Naša spoločnosť má niekoľkoročnú pôsobnosť v oblasti stavebníctva a zrealizovala desiatky zákaziek, na konci ktorých boli vždy spokojní zákazníci, z mnohých sa stali naši priatelia. Kvalitný materiál a profesionálne vyškolený
                  personál sú zárukou tej najvyššej kvality.
                </p>
              </div>
            </div>
        </div>
      </div>
    </div>

  );
}

export default Privacy;


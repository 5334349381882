import config from "../../config/config"
import { useEffect, useState } from "react"
import ServiceRowCard from "../cards/ServiceRowCard"
import { useSearchParams, useNavigate } from 'react-router-dom';
import ContactUsCard from "../cards/ContactUsCard";
const Services = () => {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const [selected, setSelected] = useState(searchParams.get("element") || -1)

  const handleServiceClick = (path: string) => {
    navigate(path)
    /* if (selected === index) {
      setSelected(-1)
    } else {
      setSelected(index)
    } */
  }

  useEffect(() => {

  }, [])
  useEffect(() => {
    document.title = "Harvistav - Naše služby"
    //@ts-ignore
    document.getElementsByTagName("META")[4].content! =`Firma Harvistav ponúka rozsiahle služby v oblasti stavebníctva.`;
    if (!searchParams.get("element")) {
      window.scrollTo({
        top: 0
      })
    }
    setSelected(parseInt(searchParams.get("element")!))
    document.getElementById(`SERVICE_ROW_CARD_${selected}`)?.scrollIntoView({behavior: "smooth"})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])


  return (
      <div className="page_animation">
        <div className="container-fluid servicesPage_intro w-100">
            <div className="container intro">
              <h1>
                <span className="green-text-darker">Ponuka</span> našich služieb
              </h1>
              <img className="fade_logo" style={{opacity: 0.8}} src={require("../../assets/images/footer_fade_logo.png")} alt="Harvistav" loading="eager" title="Harvistav" />
            </div>
        </div>
        <div className="container-fluid servicesPage_types w-100">
          <div className="container why_us">
              <div className="row">
                <div className="col-12">
                  {
                    config.services.map((service, index) =>
                      <ServiceRowCard
                        key={index}
                        index={index}
                        service={service}
                        onClick={handleServiceClick}
                        active={index === selected}
                      />
                    )
                  }
                </div>
              </div>
          </div>
        </div>


        <div className="container-fluid service_bottom_wrap w-100">
            <img className="servicesPage_bottom_background_image" src={require("../../assets/images/image44.png")} loading="lazy" alt="Harvistav" title="Harvistav" />
            <div className="container servicesPage_have_an_idea">
              <ContactUsCard />
            </div>
        </div>


      </div>

  );
}

export default Services;

